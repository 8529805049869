.versus-card {
    display: flex;
    flex-direction: row;
    height: fit-content;
    background-color: white;
    border: 2px solid black;
    background: rgb(255, 255, 255);
    background: linear-gradient(45deg, rgb(255, 255, 255) 46%, rgb(0, 0, 0) 51%);
    text-shadow: none;    
    border-radius: 50px;
  }
  .versus-card h3 {
    margin-block: 10px;
  }
  
  .left-side {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    color: rgb(0, 0, 0);
  }
  
  .middle {
    color: white;
    text-shadow: 5px 5px 10px black;
  }

  .right-side {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    color: rgb(255, 255, 255);
  }
  
  .left-text {
    
    font-size: 1em;
    text-shadow: 0px 0px 30px black;
  }
  
  .right-text {
    
    font-size: 1em;
    text-shadow: 0px 0px 30px white;
  }
.home-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    margin-block: 5px;
}
.board-container {    
    width: 40%;
    min-width: 600px;
}
.board-container h4 {
    margin-block: 0px;
    text-shadow: 1px 1px 2px black;
}
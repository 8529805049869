 
.App {
    background: rgb(255,214,111);
    background: linear-gradient(0deg, rgba(255,214,111,1) 0%, rgba(255,171,98,1) 100%);
    min-height: 100vh;
    font-size: calc(10px + 2vmin);
    color: white;
    display: grid;
    grid-template-rows: min-content 1fr min-content;
}
.topNav nav {
    display: flex;
    flex-direction: row;
    gap: 100px;
    margin-inline-start: 50px;
    margin-block: 10px;    
}
.topNav nav a {
    display: inline;
    vertical-align:middle;
    color: white;
    text-decoration: none;
}
.topNav nav a:hover {
    text-decoration: underline;
}
.topNav nav img {
    height: 56px;
}
footer {
    text-align: center;
}
.ranking-component {
    display: grid;
    grid-template-columns: 200px 3fr;
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px; 
    background-color: rgba(255,255,255,0.25);
    border-radius: 25px;
    text-shadow: 1px 1px 70px black;
}
.placement {
    display: table-cell;
    grid-row: span 2;
    text-align: center;
}
.rank-stats {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-inline-end: 25px;
}
.rank-name {
    margin-block: 0px;
}
.wdl-generic {
    background-color: green;
    padding: 5px;
    border-radius: 15px;
    color:white
}